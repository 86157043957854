* {
  margin: 0;
  padding: 0;
  font-family: 'Afta Sans Thin', sans-serif;
  box-sizing: border-box;
}

a:link {
  text-decoration: none;;
  &:hover {
    text-decoration: underline;
  }
}
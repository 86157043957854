@use '../../styles/mixin' as *;
@use '../../styles/typography' as *;
@use '../../styles/variables' as *;


* {
    box-sizing: border-box;
}

body {
    margin: 0;
}

.overlay {
    position: relative;
}

.overlay:before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.955), rgba(0, 0, 0, 0.653));
    z-index: -1;
}

.main {
    text-align: left;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 75vh;
    padding: 1rem;

    @include tablet {
        font-size: 1.75rem;
        padding: 2.5rem;

    }

    @include desktop {
        font-size: 2.25rem;
    }

    &__name {
        padding: 1rem;

        @include tablet {
            padding: 1.5rem;
        }
    }

    &__title {
        padding: 1rem;
        padding-top: 0;

        @include tablet {
            padding: 1.5rem;
            padding-top: 0;
        }
    }

    &__description {
        padding-left: 1rem;

        @include tablet {
            padding: 1.5rem;
            padding-top: 0;
        }
    }

}

.link {
    text-decoration: none;
    padding-left: 1.5rem;
    color: white;
    font-weight: bold;

    &:hover {
        color: yellow;
        transform: scale(1.1);
    }
}
@use '../../styles/mixin' as *;
@use '../../styles/typography' as *;
@use '../../styles/variables' as *;


.header {

    @include tablet {
        font-size: 1.25rem;
        padding: 1.5rem;
    }

    @include desktop {
        font-size: 2rem;
        padding: .5rem 4rem 0;
    }

    &__top {
        padding: 1rem;
        display: flex;
        justify-content: space-between;

        &-right {
            display: flex;
            justify-content: space-between;
        }
    }
}
@use '../../styles/mixin' as *;
@use '../../styles/typography' as *;
@use '../../styles/variables' as *;

.work {
    position: relative;

    &__top {
        color: white;
        padding: 4rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 2rem;
        border-bottom: 3px yellow solid;

        @include tablet {
            padding: 4rem 6rem;
        }

        @include desktop {
            padding: 5rem 12rem;
        }

        &-title {
            font-size: 1.5rem;
            font-family: 'Alpha Echo';
            position: relative;

            &::after {
                content: ':';
                color: yellow;
                position: absolute;
                top: 0;
                right: -1.5rem;
            }

            @include tablet {
                font-size: 3rem;
            }

            @include desktop {
                font-size: 4rem;
            }
        }

        &-img {
            border-radius: 50%;
            width: 15%;
        }
    }

    &__projects {
        padding: 1rem;

        @include tablet {
            padding: 4rem 6rem;
        }

        @include desktop {
            padding: 5rem 12rem;
        }

        &-project {
            border: 1px solid yellow;
            background-color: white;
            border-radius: .625rem;
            margin-bottom: 4rem;

            &__title {
                background-color: rgb(227, 222, 222);
                color: black;
                padding: .75rem;
                padding-left: 1.5rem;
                font-family: 'Alpha Echo';
                border-radius: .625rem;
                box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
            }

            &__photos {
                display: flex;
                align-items: center;

                &-photo {
                    width: 33%;
                    height: 100%;
                    padding: 1rem;

                    &:hover {
                        transform: scale(1.1);
                        cursor: grab;
                    }
                }
            }

            &__tools {
                padding: .75rem;
                padding-left: 1.5rem;
                background-color: rgba(255, 255, 0, 0.365);
                font-family: 'Alpha Echo';
                border-bottom-left-radius: .625rem;
                border-bottom-right-radius: .625rem;
            }

            &__text {
                font-size: 1rem;
                line-height: 1.5;
                padding: 1.5rem;
                font-family: 'Alpha Echo';
                color: #333;
                background-color: #f9f9f9;
                border-radius: 0.625rem;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                margin-top: 1.5rem;

                @include tablet {
                    font-size: 1rem;
                }

                @include desktop {
                    font-size: 1rem;
                }
            }
        }
    }
}

.work:before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.955), rgba(0, 0, 0, 0.653));
    z-index: -1;
}
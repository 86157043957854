@font-face {
    font-family: 'Afta Sans Thin';
    src:  url("../fonts/AftaSansThin-Regular.otf") format("truetype");
    font-weight: 400;
}

@font-face {
    font-family: 'Alpha Echo';
    src:  url("../fonts/alpha_echo.ttf") format("truetype");
    font-weight: 300;
}


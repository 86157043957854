@use '../../styles/mixin' as *;
@use '../../styles/typography' as *;
@use '../../styles/variables' as *;

.about {
    position: relative;

    &__main {
        color: white;
        padding: 4rem;

        @include tablet {
            padding: 4rem 6rem;
        }

        @include desktop {
            padding: 5rem 12rem;
        }

        &-top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 2rem;
            border-bottom: 1px yellow solid;

            @include desktop {
                padding: 0 5rem 2rem;

            }


            &__title {
                font-size: 1.5rem;
                font-family: 'Alpha Echo';

                &::after {
                    content: '!';
                    color: yellow;
                }

                @include tablet {
                    font-size: 3rem;
                }

                @include desktop {
                    font-size: 4rem;

                }
            }

            &__image {
                border-radius: 50%;
                width: 25%;

            }
        }

        &-text {
            font-size: 1rem;
            font-weight: 600;
            padding-top: 1rem;

            @include tablet {
                font-size: 1.75rem;
            }

            @include desktop {
                font-size: 2rem;
                padding-top: 2rem;
            }

        }

        &-tech {
            color: white;
            padding: 1rem 4rem 4rem;

            @include tablet {
                padding: 1rem 6rem 4rem;
            }

            @include desktop {
                padding: 1rem 12rem 5rem;
            }

            &__title {
                font-size: 1.25rem;
                padding-bottom: .5rem;;
                font-family: 'Alpha Echo';
                border-bottom: yellow 1px solid;
                &::after {
                    content: '...';
                    color: yellow;
                }

                @include tablet {
                    font-size: 2.5rem;
                }

                @include desktop {
                    font-size: 2.5rem;

                }
            }

            &__languages {
                display: flex;
                flex-direction: column;
                padding: 1rem;
                padding-top: 1.5rem;
                gap: 1rem;

                @include tablet {
                    flex-direction: row;
                    flex-wrap: wrap;
                }
                @include desktop {
                    padding-top: 2.5rem;
                }
                &-language {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &__title {
                        padding-right: .25rem;
                        
                        @include desktop {
                            font-size: 1.5rem;
                        }
                    }

                    &__icon {
                        width: 2rem;
                        @include desktop {
                            width: 2.5rem;
                        }
                    }
                }
                
            }
            
        }
        &-history{
            color: white;
            padding: 1rem 4rem 4rem;
    
            @include tablet {
                padding: 1rem 6rem 4rem;
            }
    
            @include desktop {
                padding: 1rem 12rem 5rem;
            }
    
            &__title {
                font-size: 1.25rem;
                font-family: 'Alpha Echo';
                padding-bottom: .5rem;;
                border-bottom: yellow 1px solid;
                &::after {
                    content: '...';
                    color: yellow;
                }
    
                @include tablet {
                    font-size: 2.5rem;
                }
    
                @include desktop {
                    font-size: 2.5rem;
    
                }
            }
            &__content {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: baseline;
                gap: 1rem;

                @include tablet {
                    flex-direction: row;

                }

                &-text {
                    font-size: 1rem;
                    font-weight: 600;
                    @include tablet {
                        font-size: 1.75rem;
                    }
        
                    @include desktop {
                        font-size: 2rem;
                        padding-top: 2rem;
                    }
        
                }
                &-photo {
                    padding: 1rem;
                    width: 100%;
                    height: auto;
                    align-items: center;

                    @include tablet {
                        width: 50%;
                        padding: 3rem;
                    }
                }
            }
    }
        
    }
    
}

.about:before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.955), rgba(0, 0, 0, 0.653));
    z-index: -1;
}